import { Controller } from 'stimulus';

export default class extends Controller {
  initialize(){
    this.timestamp = $('.player-timestamp');
  }

  connect(){}

  show(){
    this.timestamp.removeClass('hidden');
    this.timestamp.addClass('block');
  }

  hide(){
    this.timestamp.removeClass('block');
    this.timestamp.addClass('hidden');
  }
}

