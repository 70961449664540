import { Controller } from "stimulus"
import videojs from 'video.js'
import touchOverlay from 'videojs-overlay-buttons'

import 'video.js/dist/video-js.css'
import 'videojs-contrib-quality-levels'
import 'videojs-hls-quality-selector'
import 'videojs-thumbnail-sprite'
import 'videojs-overlay-buttons/dist/videojs-overlay-buttons.css'


export default class extends Controller {
  initialize() {
    this.video = document.querySelector('video');
    this.player = videojs(this.video, {
        controls: true,
        fluid: true,
        playbackRates: [0.5, 1, 1.5, 2],
        userActions: {
          doubleClick: false
        },    
    })
    this.files = [];

    this.buttons = this.element.querySelectorAll("button")
    this.buttons.forEach(function(button){
      button.addEventListener('click', this.createThubnails.bind(this));
    }.bind(this));
  }

  createThubnails(e){
    let timeText = e.currentTarget.parentElement.nextElementSibling.nextElementSibling.textContent;
    let curr_time = parseInt(window.Util.covertTime(timeText))
    this.player.currentTime(curr_time);
    this.showImageToTable(e);
  }

  showImageToTable(e){
    setTimeout(function(){
      this.canvas = document.createElement('canvas');
      this.canvas.width = this.video.videoWidth;
      this.canvas.height = this.video.videoHeight;

      let context = this.canvas.getContext('2d');
      context.drawImage(this.video, 0, 0, this.player.videoWidth(), this.player.videoHeight());

      let appendElement = e.target.parentElement.nextElementSibling
      if (appendElement.hasChildNodes()){ appendElement.firstChild.remove() }
      appendElement.appendChild(this.canvas);

      this.canvas.style.height = "100px"
      this.convertToFileList(e);
    }.bind(this, e), 1500);
  }

  convertToFileList(e){
    var indexArr = e.target.dataset.index
    this.canvas.toBlob(function(blob) { 
      let file = new File([blob], 'thumbnail.jpg', { type: 'image/jpeg' });
      this.files[indexArr] = file;
    }.bind(this), 'image/jpeg', 0.95);

    setTimeout(function(){
      let fileList = new DataTransfer();
      this.files.forEach(file => {
        fileList.items.add(file);
      });

      let inputFile = document.querySelector('.form-control-file');
      inputFile.files = fileList.files;

      let formThumbnails = document.querySelector("#form-thumbnails");
      if (formThumbnails.classList.contains("d-none") && inputFile.files.length == this.buttons.length){
        this.toBottomOfPage();
        formThumbnails.classList.remove("d-none");
      }
    }.bind(this), 1000);
  }

  toBottomOfPage() {
    var scrollHeight = document.body.scrollHeight;
    window.scrollTo(0, scrollHeight);
  }
}