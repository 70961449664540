import { Controller } from 'stimulus';

export default class extends Controller {
  connect(){
    this.url = this.element.dataset.url
    this.element.addEventListener('click', this.showList.bind(this));
  }

  showList(){
    window.Ajax.get(this.url, this.ajaxOptions(this.dataPost));
  }

  handleSuccess(response){
    let jsonParse = JSON.parse(response)
    if(jsonParse.html_string.length > 0){
      let tmp = document.createElement("div");
      tmp.innerHTML = jsonParse.html_string
      let comments = document.querySelector(".js-comments")
      comments.append(tmp.firstElementChild);

      let params = new URLSearchParams(this.url.split("?")[1]);
      params.set("page", parseInt(params.get('page')) + 1)
      this.url = `${this.url.split("?")[0]}?${params.toString()}`
    }else{
      this.element.remove();
    }
  }

  handleFail(response){
    console.log(response)
  }

  ajaxOptions(dataPost){
    return {
      data: JSON.stringify(dataPost),
      headers: [
        {
          key: 'X-CSRF-Token',
          value: window.Util.getCsrfToken()
        },
        {
          key: 'Content-Type',
          value: 'application/json'
        }
      ],
      onSuccess: this.handleSuccess.bind(this),
      onFail: this.handleFail.bind(this)
    }
  }
}