import { Controller } from "stimulus"
import videojs from 'video.js'
import touchOverlay from 'videojs-overlay-buttons'

import 'video.js/dist/video-js.css'
import 'videojs-contrib-quality-levels'
import 'videojs-hls-quality-selector'
import 'videojs-thumbnail-sprite'
import 'videojs-overlay-buttons/dist/videojs-overlay-buttons.css'


export default class extends Controller {
  connect() {}
  initialize() {
    this.playerTarget = this.element.querySelector("video")
    videojs.registerPlugin('touchOverlay', touchOverlay);
    
    var videoPlayer = videojs(this.playerTarget, {
      controls: true,
      fluid: true,
      playbackRates: [0.5, 1, 1.5, 2],
      userActions: {
        doubleClick: false
      },
      controlBar: {
        pictureInPictureToggle: false
      }
    });

    videoPlayer.bigPlayButton.hide();
    videoPlayer.touchOverlay({
      play: {}
    });

    const controlBar = videoPlayer.getChild('ControlBar');
    // const buttonNextVideo = new NextVideoButton(videoPlayer);
    // const buttonTimestamp = new ButtonTimestamp(videoPlayer);
    const buttonShowModule = new ShowModuleButton(videoPlayer);
    const buttonFastForward = new fastForward(videoPlayer);
    const buttonFastBackward = new fastBackward(videoPlayer);
    const buttonTheaterVideo = new theaterVideo(videoPlayer);

    // controlBar.progressControl.disable()
    controlBar.progressControl.enable()
    const jsTimestamp = document.querySelector('.js-url-timestamp');
    var isControlsEnable
    var isVideoTalks

    if(jsTimestamp){
      isControlsEnable = jsTimestamp.dataset.controls;
      isVideoTalks = jsTimestamp.dataset.isVideoTalks;
    }

    if(isControlsEnable == "true"){      

      // controlBar.addChild(buttonNextVideo, {
      //   name: 'Next Video'
      // }, 1);

      // controlBar.addChild(buttonTimestamp, {
      //   text: 'Timestamp'
      // }, 1);

      controlBar.addChild(buttonFastForward, {
        text: 'Fast Forward'
      }, 2);

      controlBar.addChild(buttonFastBackward, {
        text: 'Fast Backward'
      }, 2);

      let cbTheater = controlBar.addChild(buttonTheaterVideo, {
        text: 'Theater Mode'
      });
      controlBar.el().insertBefore(cbTheater.el(), controlBar.fullscreenToggle.el());
    }

    if (isVideoTalks == "false"){
      controlBar.addChild(buttonShowModule, {
        text: 'Lihat Module'
      }, 1);
    }

    this.setCustomVideoPlayer(videoPlayer);
    // this.setThumbnails(videoPlayer);

    if(document.querySelector('.js-url-timestamp')){
      this.setTimeUpdate(videoPlayer);
      this.timeStampClick(videoPlayer);
    }

    if (this.element.dataset.fullscreen == "true"){
      videoPlayer.enterFullWindow();
    }

    if (this.element.dataset.autoplay == "true"){
      $('.vjs-play-control').click();
    }
  
    if (this.element.dataset.onhover == 'true'){
      videoPlayer.bigPlayButton.hide();      
      videoPlayer.controlBar.hide();
      videoPlayer.muted(true); 
      this.element.addEventListener('mouseenter', function() {
        videoPlayer.play();
      }.bind(videoPlayer));

      this.element.addEventListener('mouseleave', function() {
        videoPlayer.pause();
      }.bind(videoPlayer));
      videoPlayer.pause();
    }

    this.watchedBar();
  }

  disconnect() {}

  setCustomVideoPlayer(videoPlayer){
    videoPlayer.hlsQualitySelector({ displayCurrentQuality: true})
  }

  // setThumbnails(videoPlayer){
    // if (this.element.dataset.thumbnails){
    //   this.thumbnails = JSON.parse(this.element.dataset.thumbnails)
    //   videoPlayer.thumbnailSprite({
    //     sprites: this.thumbnails,
    //   });
    // }
  // }

  timeStampClick(videoPlayer){
    let timeStamps = document.querySelectorAll(".js-timestamp");
    timeStamps.forEach(
      function(row){
        row.addEventListener('click',
          function(tm){
            let textTime = tm.currentTarget.querySelector(".font-semibold").innerText
            let curr_time = parseInt(window.Util.covertTime(textTime))
            this.player_.currentTime(curr_time);
            this.player_.pause();
          }.bind(videoPlayer)
        );
      }.bind(videoPlayer)
    )
  }

  setTimeUpdate(videoPlayer){
    videoPlayer.on('timeupdate', function() {
      const jsTimestamp = document.querySelector('.js-url-timestamp');
      const timestamp = this.currentTime().toFixed(0);
      const start_time = parseInt(this.currentTime().toFixed(0));
      const end_time = parseInt(start_time + 5);
      const url = jsTimestamp.dataset.url
      const time = jsTimestamp.dataset.time
      const max_timestamp = this.duration().toFixed(0);
      const isUpdateTimestamp = jsTimestamp.dataset.isUpdateTimestamp
      // const watchVideoId = jsTimestamp.dataset.watchVideoId
      const isTrue = (
        (max_timestamp != "NaN") &&
        (isUpdateTimestamp == "true") &&
        ((timestamp.charAt(timestamp.length - 1) == '0') || 
        timestamp.charAt(timestamp.length - 1) == '5') &&
        (parseInt(timestamp) > parseInt(time))
      )

      const timePercent = parseInt((timestamp/max_timestamp) * 100)
      if (timePercent == 1 || timePercent != this.newTimepercent){
        const EltimePercent = document.querySelector(`#stime-${timePercent - 1}`)
        if (EltimePercent){ 
          EltimePercent.remove() 
          this.newTimepercent = timePercent
        }
      }

      if (isTrue){
        jsTimestamp.dataset.time = timestamp
        window.Ajax.get(url, {
          headers: [
            {
              key: 'timestamp',
              value: timestamp
            },
            {
              key: 'duration',
              value: max_timestamp
            },
            {
              key: 'starttime',
              value: start_time
            },
            {
              key: 'endtime',
              value: end_time
            },
            {
              key: 'Content-Type',
              value: 'application/json'
            }
          ],
          onSuccess: function(response){
            console.log(response)
          },
          onFail: function(response){
            console.log(response)
          }
        });
      }
    });
  }

  watchedBar(){
    const html = `<div class="vjs-load-progress" style="width: 100%;color: red;background-color: red;top: 6px;" id="time-s"></div>`
    const div = document.createElement("div")
    div.innerHTML = html
    const para = div.firstChild
    const x = document.querySelector(".vjs-load-progress")
    x.parentElement.insertBefore(para, x)

    const jsTimestamp = document.querySelector('.js-url-timestamp');
    const watchTimes = JSON.parse(jsTimestamp.dataset.watchTimes)
    for (let i = 0; i < 100; i++) {
      if (!watchTimes.includes(i)){
        const html = `<div id="stime-${i}" style="left: ${i}%;width: 1%;"></div>`;
        const div = document.createElement("div")
        div.innerHTML = html;
        const para = div.firstChild;
        document.querySelector("#time-s").appendChild(para)
      }
    }
  }
}

class NextVideoButton extends videojs.getComponent('Button') {
  constructor(player, options = {}) {
    super(player, options);
    this.addClass('fas')
    this.addClass('fa-angle-double-right');
  }

  handleClick(_e) {
    let nextVideoButton = document.querySelector(".js-next-video");
    nextVideoButton.click();
  }
}

class ShowModuleButton extends videojs.getComponent('Button') {
  constructor(player, options = {}) {
    super(player, options);
    this.addClass('text__video');
    this.addClass('tooltiptext');
    this.addClass('fas');
    this.addClass('fa-book-open');
  }

  handleClick(_e) {
    let url = "/libraries"
    // location.href = "/libraries"
    window.open(url, '_blank');
  }
}

// class ButtonTimestamp extends videojs.getComponent('Button') {
//   constructor(player, options = {}) {
//     super(player, options);
//     this.addClass('flaticon2-rectangular');
//   }

//   handleClick(_e) {
//     console.log("timestamp")
//     const modalTimestamp = new ModalTimestamp(this.player_,
//       {
//         temporary: false,
//         description:'description',
//         label:"test",
//         closeable:true
//     });
//     this.player_.addChild(modalTimestamp);
//     modalTimestamp.on('modalopen', function() {
//       modalTimestamp.contentEl().innerHTML = document.querySelector("#modal-timestamp").innerHTML;
//     });
//     modalTimestamp.on('modalclose', function() {
//       this.player_.removeChild(modalTimestamp);
//     });
//     modalTimestamp.open();
//   }
// }

// class ModalTimestamp extends videojs.getComponent('ModalDialog'){
//   constructor(player, options = {}) {
//     super(player, options);
//   }
// }

class theaterVideo extends videojs.getComponent('Button') {
  constructor(player, options = {}) {
    super(player, options);
    this.addClass('fas');
    this.addClass('fa-arrows-alt-h');
  }

  handleClick(_e) {
    let videoBox = document.querySelector("#videoBox")
    if (videoBox){
      if (videoBox.classList.contains("col-12")){
        videoBox.classList.remove("col-12")
      }else {
        videoBox.classList.add("col-12")
      }
    }
  }
}

class fastForward extends videojs.getComponent('Button') {
  constructor(player, options = {}) {
    super(player, options);
    this.addClass('fas');
    this.addClass('fa-step-forward');
  }

  handleClick(_e) {
    this.player_.currentTime(this.player_.currentTime() + 10);
  }
}

class fastBackward extends videojs.getComponent('Button') {
  constructor(player, options = {}) {
    super(player, options);
    this.addClass('fas');
    this.addClass('fa-step-backward');
  }

  handleClick(_e) {
    this.player_.currentTime(this.player_.currentTime() - 10);
  }
}
