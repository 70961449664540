import { Controller } from 'stimulus';

export default class extends Controller {
  initialize(){
    this.modal = this.element
    this.isComplete = this.element.dataset.isComplete

    if(this.isComplete == "false"){
      $(this.modal).modal('show');
    }
  }
}
