import { Controller } from 'stimulus';

export default class extends Controller {
  connect(){
    window.setTimeout(this.close.bind(this), 5000);
  }

  close(e){
    window.Util.removeElement(this.element);
  }
}
