import { Controller } from 'stimulus';

export default class extends Controller {
  initialize(){
    this.options = JSON.parse(this.data.get('options'));
  }

  connect(){
    window.setTimeout(this.init.bind(this),500);
    // this.init();
  }

  init(){
    $(this.element).slick(this.options);
  }
}
