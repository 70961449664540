import DatatablesController from '../../../datatables_controller';

export default class extends DatatablesController {
  datatableColumns(){
    return [
      {
        field: 'index',
        title: 'No',
        sortable: false,
        width: 40,
        type: 'number',
        selector: false,
        textAlign: 'left',
        template: function(data) {
          return `<span class="font-weight-bolder">${data.index}</span>`;
        }
      },
      {
        field: 'email',
        title: 'Email',
        width: 200,
        sortable: false,
        template: function(data) {
          return `<a href="${data.show_path}" class="font-weight-bolder text-secondary">${data.email}</a>`;
        }
      },
      {
        field: 'description',
        title: 'Deskripsi',
        width: 120,
        sortable: false,
        template: function(data) {
          return `<span class="font-weight-bolder">${data.description}</span>`;
        }
      },
      {
        field: 'price',
        sortable: false,
        title: 'Total Bayar',
        width: 100,
        template: function(data) {
          return `<span class="font-weight-bolder">${data.price}</span>`;
        }
      },
      {
        field: 'status',
        sortable: false,
        title: 'Status Pembayaran',
        template: function(data) {
          if (data.status == 'paid'){
            return `<span class="btn btn-outline-success text-capitalize mr-3 my-2">${data.status}</span>`;
          }else if (data.status == 'expired'){
            return `<span class="btn btn-outline-danger text-capitalize mr-3 my-2">${data.status}</span>`;
          }else if (data.status == 'pending'){
            return `<span class="btn btn-outline-warning text-capitalize mr-3 my-2">${data.status}</span>`;
          }else{
            return `<span class="btn btn-outline-secondary text-capitalize mr-3 my-2">${data.status}</span>`;
          }
        }
      },
      {
        field: 'expired_date',
        sortable: true,
        title: 'Tanggal Pembayaran Expired',
        template: function(data) {
          return `<span class="font-weight-bolder">${data.expired_date}</span>`;
        }
      },
      {
        field: 'Actions',
        title: 'Actions',
        sortable: false,
        width: 120,
        overflow: 'visible',
        autoHide: false,
        template: function(data) {
          return `            
            <a class="btn btn-sm btn-clean btn-icon" href="${data.show_path}" title="Tampilkan">
              <i class="fa fa-eye text-primary"></i>
            </a>
          `;
        },
      }
    ]
  }

  datatableFilter(){
    $('#kt_datatable_search_payment').on('change', function(e) {
      this.datatable.search(e.target.value, e.target.dataset.paramName);
    }.bind(this));

    $('#kt_datatable_search_status').on('change', function(e) {
      this.datatable.search(e.target.value, e.target.dataset.paramName);
    }.bind(this));

    $('#kt_datatable_search_coupon').on('change', function(e) {
      this.datatable.search(e.target.value, e.target.dataset.paramName);
    }.bind(this));
  }
}
