import { Controller } from 'stimulus';

export default class extends Controller {
  initialize(){
    this.searchbar = this.element.getElementById('my-main-header-searchbar');
    this.input = this.searchbar.querySelector('input');
  }

  connect(){
    this.bindClose();
    this.bindUncloseClick();
  }

  bindClose(){
    // this.element.addEventListener('click', this.handleClose.bind(this));
  }
  bindUncloseClick(){
    this.searchbar.addEventListener('click', this.handleUncloseClick.bind(this));
    this.input.addEventListener('click', this.handleUncloseClick.bind(this));
  }

  close(){
    this.element.classList.add('d-none');
  }

  // handleClose(e){
    // this.element.classList.add('d-none');
  // }
  handleUncloseClick(e){
    e.stopPropagation();
    e.preventDefault();
  }
}


