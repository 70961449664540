import { Controller } from 'stimulus';

export default class extends Controller {
  connect(){
    window.setTimeout(this.run.bind(this), 10000);
  }

  run(){
    var lazyloadImages;
    if ("IntersectionObserver" in window) {
      lazyloadImages = document.querySelectorAll('[data-component="image-lazyload"]:not(.js-initialized)');
      var imageObserver = new IntersectionObserver(function(entries, observer) {
        entries.forEach(function(entry) {
          if (entry.isIntersecting) {
            var image = entry.target;
            image.style.backgroundImage = `url('${image.dataset.src}')`;
            image.classList.add("js-initialized");
            imageObserver.unobserve(image);
          }
        });
      });

      lazyloadImages.forEach(function(image) {
        imageObserver.observe(image);
      });
    } else {
      var lazyloadThrottleTimeout;
      lazyloadImages = document.querySelectorAll('[data-component="image-lazyload"]:not(.js-initialized)');

      function lazyload () {
        if(lazyloadThrottleTimeout) {
          clearTimeout(lazyloadThrottleTimeout);
        }

        lazyloadThrottleTimeout = setTimeout(function() {
          var scrollTop = window.pageYOffset;
          lazyloadImages.forEach(function(img) {
            if(img.offsetTop < (window.innerHeight + scrollTop)) {
              img.style.backgroundImage = `url('${img.dataset.src}')`;
              img.classList.add("js-initialized");
            }
          });
          if(lazyloadImages.length == 0) {
            document.removeEventListener("scroll", lazyload);
            window.removeEventListener("resize", lazyload);
            window.removeEventListener("orientationChange", lazyload);
          }
        }, 20);
      }

      document.addEventListener("scroll", lazyload);
      window.addEventListener("resize", lazyload);
      window.addEventListener("orientationChange", lazyload);
    }
  }
}
