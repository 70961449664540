import { Controller } from 'stimulus';

export default class extends Controller {
  initialize(){
    this.selectorsBasic = this.element.querySelector(".js-select-basic")
    this.selectorsAnnual = this.element.querySelector(".js-select-annual")
    this.subscriptionGroup = this.element.querySelector("#subscription_group")
    this.subscriptionGroup.value = "annually"
    this.form = this.element.querySelector("form")

    this.selectorsBasic.addEventListener('click', this.handleChangeBasic.bind(this));
    this.selectorsAnnual.addEventListener('click', this.handleChangeAnnual.bind(this));
  }

  handleChangeBasic(){
    this.selectorsAnnual.classList.replace("box-payment-active", "box-payment")
    this.selectorsAnnual.querySelector(".fa").classList.add("d-none")
    this.selectorsBasic.classList.replace("box-payment", "box-payment-active")
    this.selectorsBasic.querySelector(".fa").classList.remove("d-none")
    this.subscriptionGroup.value = "monthly"
  }

  handleChangeAnnual(){
    this.selectorsBasic.classList.replace("box-payment-active", "box-payment")
    this.selectorsBasic.querySelector(".fa").classList.add("d-none")
    this.selectorsAnnual.classList.replace("box-payment", "box-payment-active")
    this.selectorsAnnual.querySelector(".fa").classList.remove("d-none")
    this.subscriptionGroup.value = "annually"
  }
}