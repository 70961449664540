import DatatablesController from '../../../datatables_controller';

export default class extends DatatablesController {
  connect(){
    this.datatable = $(this.element)
      .KTDatatable(this.args());

    this.datatableFilter();
    this.checkboxGroup();
  }
  
  datatableData(){
    const dataIds = JSON.parse(this.element.dataset.userIds)
    return {
      type: 'remote',
      saveState: false,
        source: {
          read: {
            url: this.dataPath,
            headers: {
              'X-CSRF-Token': window.Util.getCsrfToken()
            },
            params: {
              dataIds: dataIds
            }
          },
        },
        pageSize: 10000,
        serverPaging: true,
        serverFiltering: true,
        serverSorting: true,
    };
  }

  datatableColumns(){
    return [
      {
        field: 'RecordID',
        title: '#',
        sortable: false,
        width: 20,
        selector: {
            class: ''
        },
        textAlign: 'center',
      },
      {
        field: 'name',
        title: 'Nama',
        width: 150,
        template: function(data) {
          return `
            <span class="d-none user-id" id="${data.id}"></span>
            <span class="font-weight-bolder">${data.name}</span>
          `;
        }
      },
      {
        field: 'email',
        title: 'Email',
        width: 250,
        template: function(data) {
          return `<span class="font-weight-bolder">${data.email}</span>`;
        }
      },
      {
        field: 'created_at',
        title: 'Tanggal Dibuat',
        template: function(data) {
          return `<span class="font-weight-bolder">${data.created_at}</span>`;
        }
      },
      {
        field: 'subscribe_days',
        title: 'Subscription',
        width: 120,
        sortable: false,
        template: function(data) {
          if (parseInt(data.subscribe_days) <= 10){
            return `<span class="font-weight-bolder text-danger">Tersisa ${data.subscribe_days} Hari</span>`;
          }else if(parseInt(data.subscribe_days) > 10 && parseInt(data.subscribe_days) <= 20){
            return `<span class="font-weight-bolder text-warning">Tersisa ${data.subscribe_days} Hari</span>`;
          }else if(parseInt(data.subscribe_days) > 20){
            return `<span class="font-weight-bolder text-success">Tersisa ${data.subscribe_days} Hari</span>`;
          }
        }
      },
      {
        field: 'Actions',
        title: 'Actions',
        sortable: false,
        width: 120,
        overflow: 'visible',
        autoHide: false,
        template: function(data) {
          return `
            <a href="${data.show_path}" class="btn btn-sm btn-clean btn-icon" title="Show">
              <i class="la la-eye text-primary"></i>
            </a>

          `;
        },
      }
    ]
  }

  checkboxGroup() {
    this.datatable.options.extensions = {
      checkbox: true
    }

    this.datatable.on("datatable-on-check", this.checkboxChange.bind(this));
    this.datatable.on("datatable-on-uncheck", this.checkboxChange.bind(this));
  }

  checkboxChange(){
    this.checkboxIds = this.datatable
      .getSelectedRecords()
      .find(".user-id")
      .map(function() { return this.id; })
      .get()

    var count = this.checkboxIds.length;
    $('#kt_datatable_selected_records').html(count);

    const userIds = document.querySelector("#user-ids")
    if(userIds){
      userIds.value = this.checkboxIds
      console.log(this.checkboxIds)
    }

    if (count > 0) {
      $('#kt_datatable_group_action_form').collapse('show');
    } else {
      $('#kt_datatable_group_action_form').collapse('hide');
    }
  }
}

