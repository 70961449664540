import { Controller } from 'stimulus';

export default class extends Controller {
  initialize(){
    this.url = this.element.dataset.url;
    this.element.addEventListener('click', this.handleClick.bind(this));
  }

  handleClick(){  
    const data = {
      url: this.url,
      appname: "SIA"
    }

    this.performRequest(data);
  }

  performRequest(data){      
    window.Ajax.post(this.url, this.ajaxOptions(data));
  }

  handleSuccess(response){
    if(response) {
      try {
        const responseJson = JSON.parse(response);
        window.open(responseJson.url, '_blank').focus();
      } catch(e) {
        console.log(e);
      }
    }
  }

  handleFail(response){
    if(response) {
      try {
        const responseJson = JSON.parse(response);
        if (responseJson.message==undefined){
          alert("Silakan login terlebih dahulu")
        }else{
          alert("Gagal Login");
        }
        
        location.href = responseJson.url;
      } catch(e) {
        console.log(e);
      }
    }
  }

  handleDone(responseText){
    console.log(responseText)
  }

  ajaxOptions(data){
    return {
      data: JSON.stringify(data),
      headers: [
        {
          key: 'X-CSRF-Token',
          value: window.Util.getCsrfToken()
        },
        {
          key: 'Content-Type',
          value: 'application/json'
        }
      ],
      onSuccess: this.handleSuccess.bind(this),
      onFail: this.handleFail.bind(this),
      onDone: this.handleDone.bind(this)
    }
  }
}
