import { Controller } from 'stimulus';

export default class extends Controller {
  connect(){
    this.url = this.element.dataset.url
    this.commentId = this.element.dataset.commentId
    this.repliesHtml = document.querySelector(`#replies-${this.commentId}.js-replies`)
    this.element.addEventListener('click', this.showList.bind(this));
  }

  showList(){
    if(this.repliesHtml.childNodes.length <= 0){
      window.Ajax.get(this.url, this.ajaxOptions(this.dataPost));
    }
  }

  handleSuccess(response){
    let jsonParse = JSON.parse(response)
    if(jsonParse.html_string.length > 0){
      let tmp = document.createElement("div");
      tmp.innerHTML = jsonParse.html_string
      this.repliesHtml.appendChild(tmp.firstElementChild);
      this.replyClick();
    }
  }

  handleFail(response){
    console.log(response)
  }

  ajaxOptions(dataPost){
    return {
      data: JSON.stringify(dataPost),
      headers: [
        {
          key: 'X-CSRF-Token',
          value: window.Util.getCsrfToken()
        },
        {
          key: 'Content-Type',
          value: 'application/json'
        }
      ],
      onSuccess: this.handleSuccess.bind(this),
      onFail: this.handleFail.bind(this)
    }
  }

  replyClick(){
    this.inputComment = document.querySelector(`#input-comment-id-${this.commentId}`)
    if(this.inputComment){
      this.cardComments = this.inputComment.parentElement.querySelectorAll('.js-single-comment')
      if(this.inputComment.classList.contains("d-none")){
        this.inputComment.classList.remove("d-none");
      }else{
        this.inputComment.classList.add("d-none");
      }

      Array.from(this.cardComments).forEach(function(element) {
        if(element.classList.contains("d-none")){
          element.classList.remove("d-none");
        }else{
          element.classList.add("d-none");
        }
      });
    }
  }
}