import DatatablesController from '../../../datatables_controller';

export default class extends DatatablesController {
  datatableColumns(){
    return [
      {
        field: 'index',
        title: 'No',
        sortable: false,
        width: 25,
        type: 'number',
        selector: false,
        textAlign: 'left',
        template: function(data) {
          return `<span class="font-weight-bolder">${data.index}</span>`;
        }
      },
      {
        field: 'name',
        title: 'Nama',
        width: 150,
        template: function(data) {
          return `<span class="font-weight-bolder">${data.name}</span>`;
        }
      },
      {
        field: 'email',
        title: 'Email',
        width: 250,
        template: function(data) {
          return `<span class="font-weight-bolder">${data.email}</span>`;
        }
      },
      {
        field: 'created_at',
        title: 'Tanggal Dibuat',
        template: function(data) {
          return `<span class="font-weight-bolder">${data.created_at}</span>`;
        }
      },
      {
        field: 'subscribe_days',
        title: 'Subscription',
        width: 120,
        sortable: false,
        template: function(data) {
          if (parseInt(data.subscribe_days) <= 10){
            return `<span class="font-weight-bolder text-danger">Tersisa ${data.subscribe_days} Hari</span>`;
          }else if(parseInt(data.subscribe_days) > 10 && parseInt(data.subscribe_days) <= 20){
            return `<span class="font-weight-bolder text-warning">Tersisa ${data.subscribe_days} Hari</span>`;
          }else if(parseInt(data.subscribe_days) > 20){
            return `<span class="font-weight-bolder text-success">Tersisa ${data.subscribe_days} Hari</span>`;
          }
        }
      },
      {
        field: 'Actions',
        title: 'Actions',
        sortable: false,
        width: 120,
        overflow: 'visible',
        autoHide: false,
        template: function(data) {
          return `
            <a href="${data.show_path}" class="btn btn-sm btn-clean btn-icon" title="Show">
              <i class="la la-eye text-primary"></i>
            </a>

          `;
        },
      }
    ]
  }
}
