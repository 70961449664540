import DatatablesController from '../../../datatables_controller';

export default class extends DatatablesController {
  datatableColumns(){
    return [
      {
        field: 'index',
        title: 'No',
        sortable: false,
        width: 40,
        type: 'number',
        selector: false,
        textAlign: 'left',
        template: function(data) {
          return `<span class="font-weight-bolder">${data.index}</span>`;
        }
      },
      {
        field: 'name',
        title: 'Name',
        width: 200,
        sortable: false,
        template: function(data) {
          const status = data.encoder_status;

          return `
          <div>
            <a href="${data.show_path}" class="font-weight-bolder text-secondary">
              ${data.name}
            </a>
          </div>

          <span
            style="font-size:12px;"
            class="font-weight-bold ${status ? 'text-success' : 'text-warning'}"
            data-controller="tooltip"
            title="${status ? 'Encoding process is already finished' : 'Encoding process is still on progress'}"
          >
            ${status ? 'DONE' : 'Waiting'}
          </span>
            `;
        }
      },
      {
        field: 'description',
        title: 'Deskripsi',
        width: 200,
        sortable: false,
        template: function(data) {
          return `<span class="font-weight-bolder">${data.description}</span>`;
        }
      },
      {
        field: 'file_name',
        title: 'Nama File Upload',
        width: 200,
        sortable: false,
        template: function(data) {
          return `
          <span class="font-weight-bolder">${data.filename}</span>
          `;
        }
      },
      {
        field: 'created_at',
        title: 'Tanggal Upload',
        width: 150,
        template: function(data) {
          return `
          <span class="font-weight-bolder">${data.created_at}</span>
          `;
        }
      },
      {
        field: 'Actions',
        title: 'Actions',
        sortable: false,
        width: 150,
        overflow: 'visible',
        autoHide: false,
        template: function(data) {
          return `
            <a class="btn btn-sm btn-clean btn-icon" href="${data.show_path}" title="Tampilkan">
              <i class="fa fa-eye text-primary"></i>
            </a>
            
            <a href="${data.delete_path}" data-method="delete" data-confirm="Apakah anda yakin ingin menghapus akun ini?" class="btn btn-sm btn-clean btn-icon" title="Delete">
              <i class="la la-trash text-danger"></i>
            </a>
          `;
        },
      }
    ]
  }
}

// <a class="btn btn-sm btn-clean btn-icon" href="${data.preview_path}" title="Tampilkan">
//   <span class="far fa-play-circle text-primary"></span>
// </a>
//<a class="btn btn-sm btn-clean btn-icon" data-toggle="modal" data-target="#editVideo-${data.id}" title="Ubah"> 
//   <i class="fa fa-pencil-alt text-primary"></i>
//</a>