
import { Controller } from 'stimulus';

export default class extends Controller {
  connect(){
    this.elReadmore = this.element.parentElement.querySelector("#readmore")
    this.elReadless = this.element.parentElement.querySelector("#readless")
    this.element.addEventListener('click', this.action.bind(this));
  }

  action(){
    if (this.elReadmore.classList.contains('d-none')){
      this.elReadmore.classList.remove("d-none");
      this.elReadless.classList.add("d-none");
      this.element.textContent = "Baca Selengkapnya"
    }else{
      this.elReadmore.classList.add("d-none");
      this.elReadless.classList.remove("d-none");
      this.element.textContent = "Baca Lebih sedikit"
    }
  }
}
