import { Controller } from 'stimulus';
import { DirectUpload } from "@rails/activestorage";

export default class extends Controller {
  connect(){
    this.bindChange();
  }

  bindChange(){
    this.element.addEventListener('change', this.handleChange.bind(this));
  }
  handleChange(e){
    this.cleanup();

    Array.from(this.element.files).forEach((file) => {      
      this.uploadFile(file);
    });
    this.element.value = null;
  }

  uploadFile(file){
    if (this.element.accept.includes(file.type)) {
      const url = this.element.dataset.directUploadUrl;
      const uploader = new Uploader(this, file, url);
      uploader.run();
    }else{
      alert("File type not allowed");
    }
  }

  cleanup(){
    this.element.closest('.form-group').querySelector('.direct-upload-container').innerHTML = '';
  }

  runPreProcess(){
    this.disableSubmitButton();
  }
  runPostProcess(){
    this.enableSubmitButton();
  }

  disableSubmitButton(){
    this.submitButton.setAttribute('disabled','disabled');
  }
  enableSubmitButton(){
    this.submitButton.removeAttribute('disabled');
  }

  get submitButton(){
    if(this._submitButton){
      return this._submitButton;
    }

    this._submitButton = this.form.querySelector('input[type="submit"]');
    return this._submitButton;
  }
  get form(){
    if(this._form){
      return this._form;
    }

    this._form = this.element.closest('form');
    return this._form;
  }
  get formGroup(){
    if(this._formGroup){
      return this._formGroup;
    }

    this._formGroup = this.element.closest('.form-group');
    return this._formGroup;
  }
  get label(){
    return this.element.nextElementSibling;
  }
}

class Uploader {
  constructor(srcInstance, file, url) {
    this.srcInstance = srcInstance;
    this.inputElement = srcInstance.element;
    this.element = srcInstance.element.closest('.form-group').querySelector('.direct-upload-container');
    this.file = file;

    this.srcInstance.runPreProcess();
    this.upload = new DirectUpload(file, url, this);
  }

  run() {
    this.upload.create((error, blob) => {
      if (error) {
        // Handle the error
      } else {
        const hiddenField = document.createElement('input')
        hiddenField.setAttribute("type", "hidden");
        hiddenField.setAttribute("value", blob.signed_id);
        hiddenField.name = this.inputElement.name;
        this.element.insertAdjacentElement('beforeend', hiddenField);

        this.srcInstance.runPostProcess();
        // this.inputElement.setAttribute('disabled','disabled');
      }
    })
  }

  directUploadWillCreateBlobWithXHR(request){
    this.directUploadId = window.Util.uuidv4();
    this.element.insertAdjacentHTML('afterbegin', `
      <div id="direct-upload-${this.directUploadId}" class="direct-upload direct-upload--pending">
        <div id="direct-upload-progress-${this.directUploadId}" class="direct-upload__progress" style="width: 0%"></div>
        <span class="direct-upload__filename"></span>
      </div>`
    );

    this.directUploadProgressContainer = this.element.querySelector(`#direct-upload-${this.directUploadId}`);
    this.directUploadProgress = this.directUploadProgressContainer.querySelector(`#direct-upload-progress-${this.directUploadId}`);
    this.element.querySelector('.direct-upload__filename').textContent = this.file.name;
  }

  directUploadWillStoreFileWithXHR(request) {
    this.directUploadProgressContainer
      .classList
      .remove("direct-upload--pending");

    request.upload.addEventListener("progress",
      event => this.directUploadDidProgress(event))
  }

  directUploadDidProgress(event) {
    const { loaded, total } = event;
    const progress = parseInt((loaded / total) * 100);

    this.directUploadProgress.style.width = `${progress}%`;
    if(progress >= 100){
      this.directUploadProgressContainer.classList.add("direct-upload--complete");
    }
  }
}
