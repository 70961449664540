import { Controller } from 'stimulus';

export default class extends Controller {
  initialize(){
    $('[data-faq-category]').on('click', function() {
      const selectedCategory = $(this).data('faq-category');
  
      $('[data-faq-category]').each(function() {
        $(this).removeClass('text-taxsam-orange');
      })
  
      $(this).addClass('text-taxsam-orange');
  
      // toggle Show and Hide FAQ
      $('[data-faq-fill]').each(function() {
        const selectedFill = $(this).data('faq-fill');
  
        $(this).addClass('hidden');
  
        if (selectedFill === selectedCategory) {
          $(this).removeClass('hidden');
        }
      })
    })
  
    $('[data-faq-category]:first').click();
  }
}