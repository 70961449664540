import { Controller } from 'stimulus';

export default class extends Controller {
  show(e){
    const container = this.getContainer(e.srcElement);
    this.toggleChildren(container, 'show');
    this.toggleActionButton(container, 'show');
  }
  hide(e){
    const container = this.getContainer(e.srcElement);
    this.toggleChildren(container, 'hide');
    this.toggleActionButton(container, 'hide');
  }

  toggleChildren(container, action){
    const childrenCommentContainer = container.querySelector('.js-comment-children-container');
    if(action === 'show'){
      childrenCommentContainer.classList.remove('hidden');
    } else {
      childrenCommentContainer.classList.add('hidden');
    }
  }
  toggleActionButton(container, action){
    const showButton = container.querySelector('.js-children-action.children-action-show');
    const hideButton = container.querySelector('.js-children-action.children-action-hide');
    if(action === 'show'){
      showButton.classList.add('hidden');
      hideButton.classList.remove('hidden');
    } else {
      showButton.classList.remove('hidden');
      hideButton.classList.add('hidden');
    }
  }

  getButton(element){
    let result = element;
    while(!result.classList.contains('js-children-action')){
      result = result.parentElement;
    }
    return result;
  }

  getContainer(element){
    let result = element;
    while(!result.classList.contains('js-comment-area-detail')){
      result = result.parentElement;
    }
    return result;
  }
}
