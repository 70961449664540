import { Controller } from "stimulus"

export default class extends Controller {
  initialize(){
    this.modal = document.querySelector(this.element.dataset.target);
    this.content = this.modal.querySelector('.js-modal-content');
    this.path = this.data.get('path');
  }

  handleClick() {
    this.showPreloader();
    window.Ajax.get(this.path, this.ajaxOptions);
  }

  showPreloader(){
    if(this.content.querySelector('.taxsam-preloader-skeleton')){
      return;
    }
    this.content.innerHTML = this.preloader;
  }

  handleSuccess(responseText){
    this.content.innerHTML = responseText;
  }

  handleFail(responseText){
    console.error('[ERROR]', responseText);
  }

  handleDone(){
  }

  get ajaxOptions(){
    return {
      onSuccess: this.handleSuccess.bind(this),
      onFail: this.handleFail.bind(this),
      onDone: this.handleDone.bind(this),
      headers: [
        {
          key: 'Content-Type',
          value: 'application/json'
        },
      ]
    };
  }

  get preloader(){
    return `
      <div class="modal-body p-10 bg-taxsam">
        <div class="taxsam-preloader-skeleton"></div>
      </div>
    `;
  }
}
