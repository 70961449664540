import { Controller } from 'stimulus';

export default class extends Controller {
  connect(){
    this.url = this.element.dataset.url
    window.KTApp.block(this.element, {});    
    window.Ajax.get(this.url, this.ajaxOptions);
  }

  handleSuccess(responseText){
    let responseJson = JSON.parse(responseText);
    window.KTApp.unblock(this.element, {});
    this.barChart(this.element, responseJson.series, responseJson.categories)
  }

  handleFail(responseText){
    console.error('[ERROR]', responseText)
    window.KTApp.unblock(this.element, {});
  }

  handleDone(){
  }

  get ajaxOptions(){
    return {
      onSuccess: this.handleSuccess.bind(this),
      onFail: this.handleFail.bind(this),
      onDone: this.handleDone.bind(this),
      headers: [
        {
          key: 'Content-Type',
          value: 'application/json'
        }
      ],
    }
  }

  barChart(myElement, dataSeries, dataCategories){
    var options = {
      series: dataSeries,
      chart: {
        id: "Grafik",
        type: 'line',
        height: 350,
        stacked: false,
        zoom: {
          enabled: false
        },
        selection: {
          enabled: false
        },
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: []
          },
          export: {
            csv: {
              filename: "GrafikTotalPendapatan",
            },
            svg: {
              filename: "GrafikTotalPendapatan",
            },
            png: {
              filename: "GrafikTotalPendapatan",
            }
          },
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 3
      },
      xaxis: {
        categories: dataCategories,
      },
      yaxis: {
        title: {
          text: ''
        },
        labels: {
          formatter: function (value) {
            if(parseInt(value)){
              return this.formatLabel(value);
            }else{
              return value
            }
          }.bind(this)
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (value) {
            if(parseInt(value)){
              return this.formatLabel(value);
            }else{
              return value
            }
          }.bind(this)
        }
      }
    };

    var chart = new ApexCharts(myElement, options);
    chart.render();
  }

  formatLabel(value){    
    return window.Util.addThousandSeparator(value) + " User";
  }
}
