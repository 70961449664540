import { Controller } from 'stimulus';

export default class extends Controller {
  connect(){
    this.element.addEventListener('click', this.showModal.bind(this));
  }

  showModal(){
    $(`#${this.element.dataset.modalId}`).modal("show");
  }
}