
import { Controller } from 'stimulus';

export default class extends Controller {
  initialize(){
    this.path = this.data.get('path')
    this.webinarTitle = this.data.get('webinarTitle')
    this.buttonWrapperClass = this.data.get('buttonWrapperClass')
    this.labelClass = this.data.get('labelClass')
  }

  handleClick(e){
    window.Ajax.delete(this.path,{
      onSuccess: this.handleSuccess.bind(this),
      onFail: this.handleFail.bind(this),
      headers:[
        {
          key: 'X-CSRF-Token',
          value: window.Util.getCsrfToken()
        },
        {
          key: 'Content-Type',
          value: 'application/json'
        }
      ]
    })
  }

  handleSuccess(responseText){
    $(".modal.show").modal('hide')
    let parsedResponse = JSON.parse(responseText);
    let buttonWrapperElement = document.querySelector(this.buttonWrapperClass)
    let labelElement = document.querySelector(this.labelClass)

    buttonWrapperElement.innerHTML = parsedResponse.homes_webinars_button_html
    labelElement.innerHTML = parsedResponse.homes_webinars_label_html

    FlashV2.show("danger", "Anda telah membatalkan mengikuti webinar: " + this.webinarTitle)
  }

  handleFail(responseText){
    console.log('[ERROR]USER_WEBINAR#DESTROY]', responseText);
  }
}
