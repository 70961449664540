import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['icon'];

  initialize(){
    this.path = this.data.get('path')
    this.resourceType = this.data.get('resourceType')
    this.resourceId = this.data.get('resourceId')
  }

  handleClick(e){
    window.Ajax.post(this.path,this.ajaxOptions);
  }

  handleSuccess(responseText){
    let parsedResponse = JSON.parse(responseText);
    if(parsedResponse.status){
      this.iconTarget.classList.add('actived');
    } else {
      this.iconTarget.classList.remove('actived');
    }
    window.FlashV2.show('success', "Berhasil!")
  }

  handleFail(responseText){
    console.log('[ERROR]ACTION_BOOKMARK_LIKE_DISLIKE#CREATE]', responseText);
    window.FlashV2.show('danger', `Gagal. ${responseText}`)
  }

  handleDone(responseText){
  }

  get payload(){
    return {
      user_bookmarks:{
        resource_type: this.resourceType,
        resource_id: this.resourceId,
      }
    };
  }

  get ajaxOptions(){
    return {
      data: JSON.stringify(this.payload),
      onSuccess: this.handleSuccess.bind(this),
      onFail: this.handleFail.bind(this),
      onDone: this.handleDone.bind(this),
      headers: [
        {
          key: 'X-CSRF-Token',
          value: window.Util.getCsrfToken()
        },
        {
          key: 'Content-Type',
          value: 'application/json'
        }
      ]
    }
  }
}
