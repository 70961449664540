export default class AdminFlash {
  static show(type, message){
    const html = this.html(type, message);
    const currentFlash = document.querySelector('#js-Flash');
    if(currentFlash){
      window.Util.removeElement(currentFlash);
    }

    document.body.insertAdjacentHTML('afterbegin', this.html(type, message));
  }

  static html(type, message){
    return `
      <div id="js-Flash" class="mt-1 col-md-8 mx-auto" style="z-index: 10000;position: absolute;width: 100%;top: 20px;right: 0;left: 0;">
        <div class="alert alert-${type} alert-dismissible fade show" role="alert">
          <button aria-label="Close" class="close" data-dismiss="alert" type="button">
            <span aria-hidden="true"> ×</span>
          </button>${message}
        </div>
      </div>
    `;
  }
}
