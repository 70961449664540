import { Controller } from 'stimulus';

export default class extends Controller {
  initialize(){
    var el = document.querySelector(`textarea[name='${this.element.name}']`);
    
    if(this.element.nextElementSibling){
      this.element.nextElementSibling.remove()
    }
    
    if(this.element.dataset.height){
      $(el).summernote({
        toolbar: [
          ['style', ['bold', 'italic', 'underline', 'clear']],
          ['font', ['strikethrough', 'superscript', 'subscript']],
          ['fontsize', ['fontsize']],
          ['color', ['color']],
          ['para', ['ul', 'ol', 'paragraph']],
          ['height', ['height']]
        ],
        height: this.element.dataset.height,
        placeholder: "Tulis Komentar"
      });
    }else{
      $(el).summernote({
        height: 400
      });
    }

    $('.dropdown-toggle').dropdown()
  }
}
