import { Controller } from 'stimulus';

export default class extends Controller {
  connect(){
    this.element.addEventListener('click', this.rule.bind(this));
  }

  rule(e){
    e.stopImmediatePropagation();
    e.preventDefault();
    var htmlRule = `
      <div>
        <ul style="text-align: left;">
          <li>1. Ujian terdiri dari 30 soal pilihan ganda dengan durasi waktu 90 menit.</li>
          <li>2. Jika soal tidak diisi maka akan dianggap jawaban salah.</li>
          <li>3. Kesuksesan ujian ditentukan dengan minimal nilai benar sebanyak 18 soal.</li>
          <li>4. Jika peserta gagal dalam ujian, maka dapat melakukan remedial 1 minggu setelah mengikuti ujian terakhir</li>
        </ul>
      </div>
    `

    if (this.element.dataset.htmloptions && this.element.dataset.htmloptions.length > 12) {
      htmlRule = this.element.dataset.htmloptions
    }
    
    var options = {
      title: 'Peraturan Ujian',
      icon: 'info',
      text: 'Peraturan Ujian',
      background: '#242424',
      html: htmlRule,
      showCancelButton: true,
      confirmButtonText: 'Mulai Ujian',
      confirmButtonColor: '#EE6123',
      cancelButtonText: "Batalkan",
      cancelButtonColor: '#656565',
    }

    Swal.fire(options).then(this.startExam.bind(this));
  }

  startExam(e){
    if (e.isConfirmed){
      Swal.fire(
        'Ujian Akan Dimulai',
        'Silakan tunggu beberapa saat.',
        'success'
      )

      setTimeout(function(){
        location.href = this.element.href
      }.bind(this), 2000);
    }
  }
}
