import { Controller } from 'stimulus';

export default class extends Controller {
  initialize(){
    this.url = this.element.dataset.url
    this.courseId = this.element.dataset.courseId
    this.button = this.element.querySelector('button')
    this.inputCode = this.element.querySelector("input")
    this.button.addEventListener('click', this.performRequest.bind(this));
  }


  performRequest(){
    window.KTApp.blockPage(this.element, {});
    const dataPost = {
      course_id: this.courseId,
      code: this.inputCode.value
    }
    window.Ajax.post(this.url, this.ajaxOptions(dataPost));
  }

  handleSuccess(response){
    const parsedResponse = JSON.parse(response);
    const modalChild = document.querySelector(`#certificate-payment-${this.element.dataset.courseId}`).firstChild
    modalChild.dataset.url = parsedResponse.url

    const modalController = this.application.getControllerForElementAndIdentifier(
      modalChild,
      "modal--dynamic"
    )
    modalController.performCouponRequest(modalChild);
    Flash.show("success", parsedResponse.notice)
  }

  handleFail(response){
    const parsedResponse = JSON.parse(response);
    const modalChild = document.querySelector(`#certificate-payment-${this.element.dataset.courseId}`).firstChild
    const modalController = this.application.getControllerForElementAndIdentifier(
      modalChild,
      "modal--dynamic"
    )
    modalController.performCouponRequest(modalChild);
    Flash.show("danger", parsedResponse.notice)
  }

  handleDone(response){
    window.KTApp.unblockPage();
  }

  ajaxOptions(dataPost){
    return {
      data: JSON.stringify(dataPost),
      headers: [
        {
          key: 'X-CSRF-Token',
          value: window.Util.getCsrfToken()
        },
        {
          key: 'Content-Type',
          value: 'application/json'
        }
      ],
      onSuccess: this.handleSuccess.bind(this),
      onFail: this.handleFail.bind(this),
      onDone: this.handleDone.bind(this)
    }
  }
}