import { Controller } from 'stimulus';

export default class extends Controller {
  initialize(){
    this.playerDetailArea = $('#player-detail-area');
    this.playerDetailActionShow = $('#player-detail-action-show');
    this.playerDetailActionHide = $('#player-detail-action-hide');
  }

  connect(){}

  show(){
    this.playerDetailArea.removeClass('hidden');
    this.playerDetailArea.addClass('block');

    this.playerDetailActionShow.removeClass('block');
    this.playerDetailActionShow.addClass('hidden');

    this.playerDetailActionHide.removeClass('hidden');
    this.playerDetailActionHide.addClass('block');
  }

  hide(){
    this.playerDetailArea.addClass('hidden');
    this.playerDetailArea.removeClass('block');

    this.playerDetailActionShow.removeClass('block');
    this.playerDetailActionShow.addClass('hidden');

    this.playerDetailActionHide.removeClass('hidden');
    this.playerDetailActionHide.addClass('block');
  }
}
