import { Controller } from 'stimulus';

export default class extends Controller {
  initialize(){
    this.container = document.getElementById('my-main-header-searchbar-container');
    this.searchbar = this.container.getElementById('my-main-header-searchbar');
    this.input = this.searchbar.querySelector('input');
  }

  connect(){
  }

  handleClick(e){
    this.container.classList.remove('d-none');
    this.input.focus();
  }
}

