import { Controller } from 'stimulus';

export default class extends Controller {
  initialize(){
    this.resultContainer = this.element.nextElementSibling;
    this.bindedSearch = this.search.bind(this);
    this.path = this.data.get('path');
  }

  connect(){
  }

  handleSearch(e){
    window.clearTimeout(this.timeoutId);
    this.resultContainer.innerHTML = 'Typing...';
    if(this.element.value.length >= 3){
      this.timeoutId = window.setTimeout(this.bindedSearch, 1000);
    }
  }

  search(){
    this.resultContainer.innerHTML = 'Loading...';
    const path = this.path.replace('-1', this.element.value);
    window.Ajax.get(path, this.ajaxOptions);
  }

  handleSuccess(responseText){
    this.resultContainer.innerHTML = responseText;
  }

  handleFail(responseText){
    console.error('[ERROR]', responseText);
  }

  handleDone(){
  }

  get ajaxOptions(){
    return {
      onFail: this.handleFail.bind(this),
      onDone: this.handleDone.bind(this),
      onSuccess: this.handleSuccess.bind(this),
      headers: [
        {
          key: 'Content-Type',
          value: 'application/json'
        },
      ]
    };
  }
}
