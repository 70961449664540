import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['replyComment'];

  handleClick(e){
    if(this.replyCommentTarget.classList.contains('hidden')){
      this.replyCommentTarget.classList.remove('hidden');
      return;
    }

    this.replyCommentTarget.classList.add('hidden');
  }

  getContainer(element){
    if(this._container){
      return this._container;
    }

    let result = element;
    while(!result.classList.contains('js-comment-area-detail')){
      result = result.parentElement;
    }
    this._container = result;
    return this._container;
  }
}

