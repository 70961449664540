import { Controller } from 'stimulus';

export default class extends Controller {
  connect(){
    this.path = this.data.get('path');
    window.Ajax.get(this.path, this.ajaxOptions);
  }

  handleSuccess(responseText){
    this.element.innerHTML = responseText;
  }

  handleFail(responseText){
    console.error('[ERROR]', responseText)
  }

  handleDone(){
  }

  get ajaxOptions(){
    return {
      onSuccess: this.handleSuccess.bind(this),
      onFail: this.handleFail.bind(this),
      onDone: this.handleDone.bind(this),
      headers: [
        {
          key: 'Content-Type',
          value: 'application/json'
        }
      ],
    }
  }
}
