import { Controller } from 'stimulus';

export default class extends Controller {
  initialize(){
    this.reportCommentModal = document.querySelector('#modal-report-comment');
    this.reportCommentForm = this.reportCommentModal.querySelector('form');
    this.originalAction = this.reportCommentForm.action;
    this.commentId = this.data.get('id');
  }

  connect(){
  }

  handleClick(){
    const path = this.originalAction.replace(/\-1/, this.commentId);
    this.reportCommentForm.setAttribute('action', path)
  }
}
