import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['icon','counter'];

  initialize(){
    this.path = this.data.get('path');
    this.resourceType = this.data.get('resourceType');
    this.resourceId = this.data.get('resourceId');
    this.like = this.data.get('like');
  }

  handleClick(e){
    window.Ajax.post(this.path,this.ajaxOptions);
  }

  handleSuccess(responseText){
    this.clearActivedIcon();

    let parsedResponse = JSON.parse(responseText);

    this.toggleIconTargetClass(parsedResponse);
    if(!!this.data.get('counter')){
      this.adaptCounter(parsedResponse);
    }

    window.FlashV2.show('success', "Berhasil!")
  }

  adaptCounter(parsedResponse){
    this.counterTarget.innerHTML = parsedResponse.source_likes_count
  }

  toggleIconTargetClass(parsedResponse){
    if(parsedResponse.destroyed){
      this.iconTarget.classList.remove('actived');
      return;
    }
    if(parsedResponse.status && this.like === 'true'){
      this.iconTarget.classList.add('actived');
      return;
    }
    if(!parsedResponse.status && this.like === 'false'){
      this.iconTarget.classList.add('actived');
      return;
    }

    this.iconTarget.classList.remove('actived');
  }

  clearActivedIcon(){
    const icons = this.likeDislikeContainer.querySelectorAll('div[data-controller="homes-v2--like-dislike"] > .video-action-icon');
    icons.forEach((icon) => {
      icon.classList.remove('actived');
    });
  }

  handleFail(responseText){
    console.log('[ERROR]ACTION_BOOKMARK_LIKE_DISLIKE#CREATE]', responseText);
    window.FlashV2.show('danger', `Gagal. ${responseText}`)
  }

  handleDone(responseText){
  }

  get payload(){
    return {
      user_likes:{
        resource_type: this.resourceType,
        resource_id: this.resourceId,
        likes: this.like
      }
    };
  }

  get likeDislikeContainer(){
    if(this._likeDislikeContainer){
      return this._likeDislikeContainer;
    }

    let result = this.element;
    while(!result.classList.contains('js-homes-v2--like-dislike-container')){
      result = result.parentElement;
    }
    this._likeDislikeContainer = result;
    return this._likeDislikeContainer;
  }

  get ajaxOptions(){
    return {
      data: JSON.stringify(this.payload),
      onSuccess: this.handleSuccess.bind(this),
      onFail: this.handleFail.bind(this),
      onDone: this.handleDone.bind(this),
      headers: [
        {
          key: 'X-CSRF-Token',
          value: window.Util.getCsrfToken()
        },
        {
          key: 'Content-Type',
          value: 'application/json'
        }
      ]
    }
  }
}
