import { Controller } from 'stimulus';

export default class extends Controller {
  initialize(){}
  connect(){}

  handleClick(e){
    const srcElement = e.srcElement;
    const categoryName = srcElement.dataset.categoryName;
    this.performFilter(categoryName);
    this.toggleActiveButtonStyle(srcElement);
  }

  toggleActiveButtonStyle(activedButton){
    for(let i=0; i<this.buttons.length; i++){
      this.buttons[i].classList.remove('bg-taxsam-orange');
    }
    activedButton.classList.add('bg-taxsam-orange');
  }

  performFilter(categoryName){
    if(categoryName == 'all'){
      Array.prototype.slice.call(
        this.lessonListContainer.querySelectorAll(`.js-lesson`)
      ).forEach((lessonElement) => {
        lessonElement.classList.remove('d-none');
      });
      return;
    }

    const actived = Array.prototype.slice.call(
      this.lessonListContainer.querySelectorAll(`.js-lesson[data-category-name="${categoryName}"]`)
    );
    const inActived = Array.prototype.slice.call(
      this.lessonListContainer.querySelectorAll(`.js-lesson:not([data-category-name="${categoryName}"])`)
    );

    actived.forEach((lessonElement) => {
      lessonElement.classList.remove('d-none');
    });
    inActived.forEach((lessonElement) => {
      lessonElement.classList.add('d-none');
    });
  }

  get buttons(){
    if(this._buttons){
      return this._buttons;
    }
    this._buttons = Array.prototype.slice.call(
      this.element.querySelectorAll('button')
    );
    return this._buttons;
  }

  get lessonListContainer(){
    if(this._lessonListContainer){
      return this._lessonListContainer;
    }
    this._lessonListContainer = this.modalBody.querySelector('.js-lesson-list-container-in-lesson-show');
    return this._lessonListContainer;
  }

  get modalBody(){
    if(this._modalBody){
      return this._modalBody;
    }

    this._modalBody = this.element;
    while(!this.modalBody.classList.contains('modal-body')){
      this._modalBody = this._modalBody.parentElement;
    }
    return this._modalBody;
  }
}

