import { Controller } from 'stimulus';

export default class extends Controller {
  initialize(){
    this.path = this.element.action;
    this.textAreaInput = this.element.querySelector('textarea');
  }

  connect(){}

  handleSubmit(e){
    e.preventDefault();
    window.Swal.showLoading();
    window.Ajax.post(this.path, this.ajaxOptions);
  }

  handleSuccess(responseText){
    const parsedResponse = JSON.parse(responseText);
    let existingComment = document.querySelector(`div[data-comment-id="${parsedResponse.comment_id}"]`);

    if(existingComment){
      let parent = existingComment.parentElement;
      window.Util.removeElement(existingComment);
      parent.insertAdjacentHTML('afterbegin', parsedResponse.comment_item_html);
    } else {
      this.commentContainer.insertAdjacentHTML('afterbegin', parsedResponse.comment_item_html);
    }

    window.FlashV2.show('success', `${parsedResponse.message}`);
    this.teardown();
  }
  handleFail(responseText){
    console.error('[ERROR]', responseText);
    const parsedResponse = JSON.parse(responseText);
    window.FlashV2.show('danger', `${parsedResponse.message}`);
  }
  handleDone(){
    window.Swal.close();
  }

  teardown(){
    this.textAreaInput.value = '';
    this.textAreaInput.innerHTML = '';
  }

  get commentContainer(){
    if(this._commentContainer){
      return this._commentContainer;
    }

    if(this.data.get('reply')){
      let result = this.element;
      while(!result.classList.contains('js-comment-area-detail')){
        result = result.parentElement;
      }
      this._commentContainer = result.querySelector('.js-comment-reply-container');
      return this._commentContainer;
    }

    this._commentContainer = document.querySelector('.js-comment-container');
    return this._commentContainer;
  }

  get ajaxOptions(){
    return {
      data: this.payload,
      onSuccess: this.handleSuccess.bind(this),
      onFail: this.handleFail.bind(this),
      onDone: this.handleDone.bind(this),
      headers: [
        {
          key: 'X-CSRF-Token',
          value: window.Util.getCsrfToken()
        },
      ]
    }
  }

  get payload(){
    return new FormData(this.element);
  }
}
