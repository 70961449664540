import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['container','button'];

  connect(){
    this.originalText = this.data.get('originalText');
  }

  handleClick(){
    if(this.containerTarget.dataset.open && this.containerTarget.dataset.open === 'open'){
      this.containerTarget.innerHTML = this.originalText.substring(0,100);
      this.containerTarget.dataset.open = 'closed';
      this.buttonTarget.innerHTML = this.buttonTarget.dataset.textClose;
      return;
    }

    this.containerTarget.innerHTML = this.originalText;
    this.containerTarget.dataset.open = 'open';
    this.buttonTarget.innerHTML = this.buttonTarget.dataset.textOpen;
  }
}

