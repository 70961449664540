import { Controller } from 'stimulus';
import videojs from 'video.js'

export default class extends Controller {
  initialize(){
    this.dataId = $(this.element).data('id')
  }

  connect(){
    var splide = new Splide( `.splide.js-dataId-${this.dataId}-jumbotron`, {
      perPage: 1,
      rewind : true,
      pagination: true,
      autoplay: true,
      arrows: true,
      gap: 10,
    } );

    splide.mount();

    splide.on('active', function(e) {
      const bannerType = e.slide.firstElementChild.dataset.bannerType
      if (bannerType == "video") {
        e.slide.querySelector("video").play();
        // console.log("played")
      }
    });

    splide.on('inactive', function(e) {
      const bannerType = e.slide.firstElementChild.dataset.bannerType
      if (bannerType == "video") {
        e.slide.querySelector("video").pause();
        // console.log("paused")
      }
    });
  }
}
