import DatatablesController from '../../../datatables_controller';

export default class extends DatatablesController {
  datatableColumns(){
    return [
      {
        field: 'index',
        title: 'No',
        sortable: false,
        width: 25,
        type: 'number',
        selector: false,
        textAlign: 'left',
        template: function(data) {
          return `<span class="font-weight-bolder">${data.index}</span>`;
        }
      },
      {
        field: 'created_at',
        title: 'Tanggal Dibuat',
        template: function(data) {
          return `<span class="font-weight-bolder">${data.created_at}</span>`;
        }
      },
      {
        field: 'certificate',
        title: 'Nama Sertifikat',
        sortable: false,
        width: 200,
        template: function(data) {
          return `<span class="font-weight-bolder">${data.brevet_name}</span>`;
        }
      },
      {
        field: 'user_aproval',
        title: 'User Approval',
        sortable: false,
        width: 150,
        template: function(data) {
          return `<span class="font-weight-bolder">${data.user_aproval}</span>`;
        }
      },
      {
        field: 'total_certificate',
        title: 'Total Sertifikat',
        sortable: false,
        width: 100,
        template: function(data) {
          return `<span class="font-weight-bolder">${data.total_certificate}</span>`;
        }
      },
      {
        field: 'status',
        title: 'status',
        sortable: false,
        width: 120,
        template: function(data) {
          if(data.status == "waiting_approval") {
            return `<label class="label label-inline label-warning font-weight-bold">${data.status}</label>`;
          }else if(data.status == "rejected") {
            return `<label class="label label-inline label-danger font-weight-bold">${data.status}</label>`;
          }{
            return `<label class="label label-inline label-primary font-weight-bold">${data.status}</label>`;
          }
          
        }
      },
      {
        field: 'Actions',
        title: 'Actions',
        sortable: false,
        width: 120,
        overflow: 'visible',
        autoHide: false,
        template: function(data) {
          return `
            <a class="btn btn-sm btn-clean btn-icon" href="${data.show_path}" title="Tampilkan">
              <i class="fa fa-eye text-primary"></i>
            </a>
          `;
        },
      }
    ]
  }
}
