import { Controller } from 'stimulus';

export default class extends Controller {
  initialize(){
    this.comment = $('.comment');
  }

  connect(){}

  show(){
    this.comment.removeClass('hidden');
    this.comment.addClass('block');
  }

  hide(){
    this.comment.removeClass('block');
    this.comment.addClass('hidden');
  }
}

