import { Controller } from 'stimulus';

export default class extends Controller {
  initialize(){
    // setup tailwind
    tailwind.config = {
      variants: {
        extend: {
          translate: ['group-hover', 'hover'],
        },
      },
      theme: {
        extend: {
          colors: {
            taxsam: {
              DEFAULT: '#242424',
              border: '#353333',
              warning: '#FFA800',
              danger: '#dc3545',
              orange: '#EE6123'
            }
          }
        }
      }
    }

    $(function () {
      $('[data-toggle="tooltip"]').tooltip()
    })
  }

  connect(){
    // for carousel
    $(document).ready(function() {
      $('#carousel-single-item').slick({
        autoplay: true,
        dots: true,
        prevArrow: '<button type="button" class="slick-prev left-4 z-10"></button>',
        nextArrow: '<button type="button" class="slick-next right-4 z-10"></button>',
        responsive: [
          {
            breakpoint: 1200,
            settings: {
              arrows: false
            }
          },
        ]
      });

      $('#carousel-mitra').slick({
        autoplay: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        prevArrow: '<button type="button" class="slick-prev left-4 z-10"></button>',
        nextArrow: '<button type="button" class="slick-next right-4 z-10"></button>',
        responsive: [
          {
            breakpoint: 427,
            settings: {
              arrows: false,
              slidesToShow: 2,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 770,
            settings: {
              arrows: false,
              slidesToShow: 4,
              slidesToScroll: 1,
            }
          },
        ]
      });

      $('#carousel-talks').slick({
        autoplay: false,
        slidesToShow: 6,
        slidesToScroll: 6,
        prevArrow: '<button type="button" class="slick-prev -left-9 z-10"></button>',
        nextArrow: '<button type="button" class="slick-next -right-8 z-10"></button>',
        responsive: [
          {
            breakpoint: 427,
            settings: {
              arrows: false,
              slidesToShow: 2,
              slidesToScroll: 2,
            }
          },
          {
            breakpoint: 770,
            settings: {
              arrows: false,
              slidesToShow: 3,
              slidesToScroll: 3,
            }
          },
          {
            breakpoint: 1200,
            settings: {
              arrows: false,
              slidesToShow: 4,
              slidesToScroll: 4,
            }
          }
        ]
      });

      $('#carousel-live-online').slick({
        autoplay: false,
        slidesToShow: 5,
        slidesToScroll: 5,
        prevArrow: '<button type="button" class="slick-prev -left-8 z-10"></button>',
        nextArrow: '<button type="button" class="slick-next -right-7 z-10"></button>',
        responsive: [
          {
            breakpoint: 427,
            settings: {
              arrows: false,
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 770,
            settings: {
              arrows: false,
              slidesToShow: 3,
              slidesToScroll: 3,
            }
          },
          {
            breakpoint: 1200,
            settings: {
              arrows: false,
              slidesToShow: 3,
              slidesToScroll: 3,
            }
          },
          {
            breakpoint: 1441,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
            }
          }
        ]
      });

      // $('.cvl-list').each(function() {
        // $(this).slick({
          // autoplay: false,
          // slidesToShow: 6,
          // slidesToScroll: 6,
          // prevArrow: '<button type="button" class="slick-prev -left-9 z-10"></button>',
          // nextArrow: '<button type="button" class="slick-next -right-8 z-10"></button>',
          // responsive: [
            // {
              // breakpoint: 427,
              // settings: {
                // arrows: false,
                // slidesToShow: 2,
                // slidesToScroll: 2,
              // }
            // },
            // {
              // breakpoint: 770,
              // settings: {
                // arrows: false,
                // slidesToShow: 3,
                // slidesToScroll: 3,
              // }
            // },
            // {
              // breakpoint: 1200,
              // settings: {
                // arrows: false,
                // slidesToShow: 4,
                // slidesToScroll: 4,
              // }
            // },
            // {
              // breakpoint: 1441,
              // settings: {
                // slidesToShow: 5,
                // slidesToScroll: 5,
              // }
            // }
          // ]
        // });
      // })

    });

    // for video stream preview
    // document.addEventListener('DOMContentLoaded', function () {
      // // Use .taxsam-video class for this
      // var videos = document.querySelectorAll('.taxsam-video');

      // videos.forEach(function (video) {
        // var currentVideo = videojs(video);

        // // Pause the video initially
        // currentVideo.pause();

        // // Add hover event listener
        // currentVideo.on('mouseenter', function () {
          // // Start playing on hover
          // currentVideo.play();
        // });

        // // Add timeupdate event listener
        // currentVideo.on('timeupdate', function () {
          // // Check if more than 15 seconds have elapsed
          // if (currentVideo.currentTime() > 15) {
            // // Rewind to the beginning
            // currentVideo.currentTime(0);
          // }
        // });

        // // Add mouse leave event listener
        // currentVideo.on('mouseleave', function () {
          // // Pause when the mouse leaves the video area
          // currentVideo.pause();
          // currentVideo.currentTime(0); // Rewind to the beginning
        // });
      // });
    // });

  }

  toggleNavMenu() {
    const target = '#mobile-menu';
    if ($(target).hasClass('block')) {
      $(target).removeClass('block');
      $(target).addClass('hidden');
    } else {
      $(target).removeClass('hidden');
      $(target).addClass('block');
    }
  }

}
