import { Controller } from 'stimulus';

export default class extends Controller {
  connect(){
    this.url = this.element.dataset.url
    window.KTApp.block(this.element.nextElementSibling, {});    
    window.Ajax.get(this.url, this.ajaxOptions);
  }

  handleSuccess(responseText){
    let responseJson = JSON.parse(responseText);
    window.KTApp.unblock(this.element.nextElementSibling, {});
    this.pieChart(this.element, responseJson.series, responseJson.categories)
  }

  handleFail(responseText){
    console.error('[ERROR]', responseText)
    window.KTApp.unblock(this.element.nextElementSibling, {});
  }

  handleDone(){
  }

  get ajaxOptions(){
    return {
      onSuccess: this.handleSuccess.bind(this),
      onFail: this.handleFail.bind(this),
      onDone: this.handleDone.bind(this),
      headers: [
        {
          key: 'Content-Type',
          value: 'application/json'
        }
      ],
    }
  }

  pieChart(myElement, dataSeries, dataCategories){
    var options = {
      series: dataSeries,
      chart: {
        type: 'pie'
      },
      labels: dataCategories,
      legend: {
        show: true,
        showForSingleSeries: false,
        showForNullSeries: true,
        showForZeroSeries: true,
        position: 'bottom'
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 400
          },
          legend: {
            position: 'left'
          }
        }
      }]
    };

    var chart = new ApexCharts(myElement, options);
    chart.render();
  }
}
