import DatatablesController from '../../../datatables_controller';

export default class extends DatatablesController {
  datatableColumns(){
    return [
      {
        field: 'index',
        title: 'No',
        sortable: false,
        width: 25,
        type: 'number',
        selector: false,
        textAlign: 'left',
        template: function(data) {
          return `<span class="font-weight-bolder">${data.index}</span>`;
        }
      },
      {
        field: 'created_at',
        title: 'Tanggal Dibuat',
        width: 100,
        template: function(data) {
          return `<span class="font-weight-bolder">${data.created_at}</span>`;
        }
      },
      {
        field: 'name',
        title: 'Nama',
        sortable: false,
        width: 100,
        template: function(data) {
          return `<span class="font-weight-bolder">${data.name}</span>`;
        }
      },
      {
        field: 'data_users',
        title: 'User List',
        sortable: false,
        width: 300,
        template: function(data) {
          return `<span class="font-weight-bolder" data-controller='tooltip' data-title="${data.data_user_raws}">${data.data_users}</span>`;
        }
      },
      {
        field: 'total_data',
        title: 'Total Data',
        sortable: false,
        width: 100,
        template: function(data) {
          return `<span class="font-weight-bolder">${data.total_data_users}</span>`;
        }
      },
      {
        field: 'status',
        title: 'status',
        sortable: false,
        width: 120,
        template: function(data) {
          if(data.status) {
            return `<label class="label label-inline label-success font-weight-bold">Selesai</label>`;
          }else{
            return `<label class="label label-inline label-warning font-weight-bold">Sedang Diproses</label>`;
          }
        }
      },
      {
        field: 'Actions',
        title: 'Actions',
        sortable: false,
        width: 200,
        overflow: 'visible',
        autoHide: false,
        template: function(data) {
          if(data.status) {
            return `
              <a class="btn btn-sm btn-primary mr-2" href="${data.download_path}" title="Download">
                <i class="fa fa-file-excel mr-1"></i>
                Download File
              </a>

              <a href="${data.delete_path}" data-method="delete" data-confirm="Apakah anda yakin ingin menghapus Laporan ini?" class="btn btn-sm btn-danger btn-icon" title="Delete">
                <i class="fa fa-trash"></i>
              </a>
            `;
          }else{
            return `
              <a href="${data.delete_path}" data-method="delete" data-confirm="Apakah anda yakin ingin menghapus Laporan ini?" class="btn btn-sm btn-danger btn-icon" title="Delete">
                <i class="fa fa-trash"></i>
              </a>
            `;
          }
        },
      }
    ]
  }
}
