import { Controller } from 'stimulus';

export default class extends Controller {
  initialize(){
    this.url = this.element.dataset.url
    this.like = this.element.querySelector('#like')
    this.reply = this.element.querySelector('#reply')
    this.videoId = this.element.dataset.videoId
    this.commentId = this.element.dataset.commentId
    this.like.addEventListener('click', this.likeClick.bind(this));
    if(this.reply){
      this.reply.addEventListener('click', this.replyClick.bind(this));
    }
  }

  likeClick(){
    this.dataPost = { 
      like: true,
      video_id: this.videoId
    }
    window.Ajax.put(this.url, this.ajaxOptions(this.dataPost));
  }

  replyClick(){
    this.inputComment = document.querySelector(`#input-comment-id-${this.commentId}`)
    if(this.inputComment){
      this.cardComments = this.inputComment.parentElement.querySelectorAll('.js-single-comment')
      if(this.inputComment.classList.contains("d-none")){
        this.inputComment.classList.remove("d-none");
      }else{
        this.inputComment.classList.add("d-none");
      }

      Array.from(this.cardComments).forEach(function(element) {
        if(element.classList.contains("d-none")){
          element.classList.remove("d-none");
        }else{
          element.classList.add("d-none");
        }
      });
    }
  }

  handleSuccess(response){
    let jsonParse = JSON.parse(response)
    let tmp = document.createElement("div");
    tmp.innerHTML = jsonParse.html_string

    this.replacer = document.querySelector(`#${tmp.firstElementChild.id}`).querySelector("div[data-controller='comments--like-reply']")
    this.newHtml = tmp.querySelector("div[data-controller='comments--like-reply']")
    this.replacer.replaceChildren(this.newHtml)

    if(this.replacer.querySelector("#like.text-white")){
      Flash.show("warning", "Anda Menghilangkan Like!")
    }else{
      Flash.show("success", "Anda Menambahakan Like!")
    }
  }

  handleFail(response){
    Flash.show("danger", `Gagal ${response}`)
  }

  ajaxOptions(dataPost){
    return {
      data: JSON.stringify(dataPost),
      headers: [
        {
          key: 'X-CSRF-Token',
          value: window.Util.getCsrfToken()
        },
        {
          key: 'Content-Type',
          value: 'application/json'
        }
      ],
      onSuccess: this.handleSuccess.bind(this),
      onFail: this.handleFail.bind(this)
    }
  }
}