import DatatablesController from '../../../datatables_controller';

export default class extends DatatablesController {
  datatableColumns(){
    return [
      {
        field: 'index',
        title: 'No',
        sortable: false,
        width: 40,
        type: 'number',
        selector: false,
        textAlign: 'left',
        template: function(data) {
          return `<span class="font-weight-bolder">${data.index}</span>`;
        }
      },
      {
        field: 'user',
        title: 'Nama User',
        sortable: false,
        width: 100,
        template: function(data) {
          return `<span class="font-weight-bolder">${data.user}</span>`;
        }
      },
      {
        field: 'resource',
        title: 'Nama Materi',
        sortable: false,
        width: 250,
        template: function(data) {
          return `
          <a href="${data.show_source_path}" title="Tampilkan">
            <span class="font-weight-bolder">${data.name}</span>
          </a>`;
        }
      },
      {
        field: 'resource_type',
        title: 'Tipe Materi',
        sortable: false,
        width: 80,
        template: function(data) {
          return `<span class="font-weight-bolder">${data.resource_type}</span>`;
        }
      },
      {
        title: 'Tanggal',
        field: 'Tanggal',
        sortable: false,
        width: 100,
        template: function(data) {
          return `<span class="font-weight-bolder">${data.date}</span>`;
        }
      },
      {
        title: 'laporan',
        field: 'reports',
        sortable: false,
        width: 100,
        template: function(data) {
          return `<span class="font-weight-bolder">${data.reports}</span>`;
        }
      },
      {
        field: 'comment',
        title: 'Komentar',
        sortable: false,
        overflow: 'visible',
        autoHide: false,
        width: 300,
        template: function(data) {
          return `<span class="font-weight-bolder">${data.comment}</span>`;
        }
      },
      {
        field: 'action',
        title: 'Aksi',
        sortable: false,
        overflow: 'visible',
        autoHide: false,
        width: 200,
        template: function(data) {
          if(data.hide){
            return `
              <a href="${data.hide_path}" data-method="delete" data-confirm="Apakah anda yakin ingin menghide Komentar ini?" class="btn btn-sm btn-clean btn-icon" title="Sembunyikan">
                <i class="fa fa-eye text-primary"></i>
              </a>
              <a href="${data.delete_path}" data-method="delete" data-confirm="Apakah anda yakin ingin menghapus Komentar ini?" class="btn btn-sm btn-clean btn-icon" title="Hapus">
                <i class="la la-trash text-danger"></i>
              </a>
            `;
          }else{
            return `
              <a href="${data.hide_path}" data-method="delete" data-confirm="Apakah anda yakin ingin menghide Komentar ini?" class="btn btn-sm btn-clean btn-icon" title="Sembunyikan">
                <i class="la la-eye-slash text-warning"></i>
              </a>
              <a href="${data.delete_path}" data-method="delete" data-confirm="Apakah anda yakin ingin menghapus Komentar ini?" class="btn btn-sm btn-clean btn-icon" title="Hapus">
                <i class="la la-trash text-danger"></i>
              </a>
            `;
          }
          
        }
      },
    ]
  }
}
