import DatatablesController from '../../../datatables_controller';

export default class extends DatatablesController {
  connect(){
    this.datatable = $(this.element)
      .KTDatatable(this.args());

    this.datatableFilter();
    this.checkboxGroup();
  }


  datatableData(){
    return {
      type: 'remote',
      saveState: false,
        source: {
          read: {
            url: this.dataPath,
            headers: {
              'X-CSRF-Token': window.Util.getCsrfToken()
            }
          },
        },
        pageSize: 100,
        serverPaging: true,
        serverFiltering: true,
        serverSorting: true,
    };
  }

  datatableColumns(){
    return [
      {
        field: 'RecordID',
        title: '#',
        sortable: false,
        width: 20,
        selector: {
            class: ''
        },
        textAlign: 'center',
      },
      {
        field: 'index',
        title: 'No',
        sortable: false,
        width: 25,
        type: 'number',
        selector: false,
        textAlign: 'left',
        template: function(data) {
          return `
            <span class="font-weight-bolder">${data.index}</span>
            <span class="d-none certificate-id" id="${data.certificate_id}"></span>
          `;
        }
      },
      {
        field: 'name',
        title: 'User',
        sortable: false,
        width: 200,
        template: function(data) {
          // return `<span class="font-weight-bolder">${data.name}</span>`;
          var html_output = `
            <div class="d-flex align-items-center">
              <div class="symbol symbol-40 symbol-sm flex-shrink-0">
                <img class="" src="${data.user_avatar_url}" alt="photo">
              </div>
              <div class="ml-4">
                <div class="text-dark-75 font-weight-bolder font-size-lg mb-0">${data.name}</div>
                <a href="#" class="text-muted font-weight-bold text-hover-primary">${data.company_name}</a>
              </div>
            </div>
          `;

          return html_output;
        }
      },
      {
        field: 'email',
        title: 'Email',
        sortable: false,
        width: 200,
        template: function(data) {
          return `<span class="font-weight-bolder">${data.email}</span>`;
        }
      },
      {
        field: 'certificate',
        title: 'Nama Sertifikat',
        sortable: false,
        template: function(data) {
          return `<span class="font-weight-bolder">${data.certificate_name}</span>`;
        }
      },
      {
        field: 'created_at',
        title: 'Tanggal Dibuat',
        template: function(data) {
          return `<span class="font-weight-bolder">${data.created_at}</span>`;
        }
      },
      {
        field: 'status',
        title: 'Status',
        template: function(data) {
          const status = data.status;

          if (status == 'rejected'){
            return `<span 
              style="font-size:12px;" 
              class="label label-inline label-danger font-weight-bold" 
              data-controller="tooltip" 
              title="${data.rejected_message}"
            >
              Rejected
            </span>`;
          }else if (status){
            return `<span 
              style="font-size:12px;" 
              class="label label-inline label-success font-weight-bold" 
              data-controller="tooltip" 
              title="Berhasil di Approve"
            >
              Approved
            </span>`;
          }else{
            return `<span 
              style="font-size:12px;" 
              class="label label-inline label-warning font-weight-bold" 
              data-controller="tooltip" 
              title="Menunggu di Approve"
            >
              Waiting Approval
            </span>`;
          }
          
        }
      }
    ]
  }

  checkboxGroup() {
    this.datatable.options.extensions = {
      checkbox: true
    }

    this.datatable.on("datatable-on-check", this.checkboxChange.bind(this));
    this.datatable.on("datatable-on-uncheck", this.checkboxChange.bind(this));
  }

  checkboxChange(){
    this.checkboxIds = this.datatable
      .getSelectedRecords()
      .find(".certificate-id")
      .map(function() { return this.id; })
      .get()

    var count = this.checkboxIds.length;
    $('#kt_datatable_selected_records').html(count);

    if(document.querySelector("#certificate-ids")){
      document.querySelector("#certificate-ids").value = this.checkboxIds
    }

    if(document.querySelector("#certificate-ids2")){
      document.querySelector("#certificate-ids2").value = this.checkboxIds
    }

    if (count > 0) {
      $('#kt_datatable_group_action_form').collapse('show');
    } else {
      $('#kt_datatable_group_action_form').collapse('hide');
    }
  }
}
