import { Controller } from 'stimulus';

export default class extends Controller {
  initialize(){
    this.url = this.element.dataset.url
    $(this.element).on('select2:select', this.selectedFilter.bind(this))
  }

  selectedFilter() {
    this.dataParams = this.element.dataset.dataParams
    this.dataPost = {}
    this.dataPost[this.dataParams] = this.element.value
    window.Ajax.post(this.url, this.ajaxOptions(this.dataPost));
  }

  handleSuccess(response){
    let jsonParse = JSON.parse(response)
    let tmp = document.createElement("div");
    tmp.innerHTML = jsonParse.render_html

    let parEl = this.element.parentElement
    let parentNodes = parEl.parentElement.children

    if (parEl == parentNodes[0]){
      if (parentNodes[2] && !parentNodes[2].classList.contains("btn-submit")){ parentNodes[2].remove(); }
      if (parentNodes[1] && !parentNodes[1].classList.contains("btn-submit")){ parentNodes[1].remove(); }
    }else if (parEl == parentNodes[1]){
      if (parentNodes[2] && !parentNodes[2].classList.contains("btn-submit")){ parentNodes[2].remove(); }
    }

    if (tmp.firstElementChild){
      parEl.insertAdjacentElement("afterend", tmp.firstElementChild)
    }
  }

  handleFail(response){
    console.log(response);
  }

  ajaxOptions(dataPost){
    return {
      data: JSON.stringify(dataPost),
      headers: [
        {
          key: 'X-CSRF-Token',
          value: window.Util.getCsrfToken()
        },
        {
          key: 'Content-Type',
          value: 'application/json'
        }
      ],
      onSuccess: this.handleSuccess.bind(this),
      onFail: this.handleFail.bind(this)
    }
  }
}