import { Controller } from 'stimulus';

export default class extends Controller {
  connect(){
    this.firstState();
    this.startTimer(this.element.dataset.startDate, this.element)
  }

  firstState(){
    if (localStorage['time_exam']){
      this.element.textContent = localStorage['time_exam']
    }else{
      this.element.textContent = "loading.."
    }
  }

  startTimer(duration, display) {
    var timer = duration, minutes, seconds;

    setInterval(function () {
      minutes = parseInt(timer / 60, 10);
      seconds = parseInt(timer % 60, 10);

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      display.textContent = minutes + ":" + seconds;
      localStorage['time_exam'] = display.textContent
      if (--timer < 0) {
        timer = 100
        localStorage.removeItem('time_exam');
        window.location.reload();
      }
    }, 1000);
  }
}
