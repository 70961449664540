import DatatablesController from '../../../datatables_controller';

export default class extends DatatablesController {
  initialize(){
    super.initialize();
    this.status = document.querySelector('select[name="status"]');
  }

  connect(){
    super.connect();
    this.bindChangeStatus();
  }

  bindChangeStatus(){
    $(this.status).on('select2:select', function(x){
      this.datatable.spinnerCallback(true);
      this.datatable.search(this.status.value, 'status');

      this.datatable.on("datatable-on-ajax-done", function(){
        this.datatable.spinnerCallback(false);
      }.bind(this));
    }.bind(this));
  }

  datatableColumns(){
    return [
      {
        field: 'index',
        title: 'No',
        sortable: false,
        width: 25,
        type: 'number',
        selector: false,
        textAlign: 'left',
        template: function(data) {
          return `<span class="font-weight-bolder">${data.index}</span>`;
        }
      },
      {
        field: 'name',
        title: 'Nama',
        autoHide: false,
        width: 200,
        template: function(data) {
          return `<span class="font-weight-bolder">${data.name}</span>`;
        }
      },
      {
        field: 'email',
        title: 'Email',
        autoHide: false,
        width: 250,
        template: function(data) {
          return `<span class="font-weight-bolder">${data.email}</span>`;
        }
      },
      {
        field: 'score',
        title: 'Nilai Ujian',
        sortable: false,
        textAlign: 'center',
        width: 100,
        template: function(data) {
          return `<span class="font-weight-bolder">${data.score}</span>`;
        }
      },
      {
        field: 'status',
        title: 'Status Ujian',
        sortable: false,
        width: 250,
        template: function(data) {
          var text = "";
          if (data.status == "Belum Ujian") {
            text = `<label class="label label-lg label-light-warning label-inline">${data.status}</label>`;
          }else if (data.status == "Lulus") {
            text = `<label class="label label-lg label-light-success label-inline">${data.status}</label>`;
          }else if (data.status == "Tidak Lulus") {
            text = `<label class="label label-lg label-light-danger label-inline">${data.status}</label>`;
          }else if (data.status == "Sedang Ujian"){
            text = `<label class="label label-warning label-inline">${data.status}</label>`;
          }else {
            text = `<label class="label label-light-primary label-inline">${data.status}</label>`;
          }

          // if (data.remedial.length > 1){
            // text += `<label class="label label-light-primary label-inline ml-3">${data.remedial}</label>`;
          // }
          return text
        }
      },
      {
        field: 'detail_exam',
        title: 'Detail Ujian',
        sortable: false,
        textAlign: 'center',
        template: function(data) {
          return `
            <button
              class="btn btn-sm p-1 btn-secondary bg-white ml-3"
              data-toggle="modal"
              data-target="#detailCertificate" x
              data-dynamic-data-id="${data.exam_id}"
              title="Detail"
            >
              <span>Lihat Detail</span>
              <i class="fas fa-angle-double-right"></i>
            </button>
          `;
        }
      },
      {
        field: 'action',
        title: 'Aksi',
        sortable: false,
        overflow: 'visible',
        autoHide: false,
        textAlign: 'center',
        template: function(data) {
          return `
            <div class="dropdown dropdown-inline">
              <a class="btn btn-primary btn-sm font-weight-bolder dropdown-toggle px-5" data-toggle="dropdown" >Menu</a>
              <div class="dropdown-menu dropdown-menu-sm dropdown-menu-right" x-placement="bottom-end" style="position: absolute; transform: translate3d(-88px, 33px, 0px); top: 0px; left: 0px; will-change: transform;">
                <ul class="navi navi-hover">
                  <li class="navi-header pb-1">
                    <span class="text-primary text-uppercase font-weight-bold font-size-sm">Aksi:</span>
                  </li>
                  <li class="navi-item">
                    <a href="${data.user_path}" class="navi-link" type="button">
                      <span class="navi-icon">
                        <i class="flaticon2-graph-1"></i>
                      </span>Lihat Data User </a>
                  </li>
                  <li class="navi-item">
                    <a class="navi-link"
                      href="${data.remedial_path}"
                      data-confirm="apakah anda yakin akan melakukan remedial ujian ini?"
                    >
                      <span class="navi-icon">
                        <i class="fa fa-calendar"></i>
                      </span>
                      Jadwalkan Remedial
                    </a>
                  </li>
                  <li class="navi-item">
                    <a class="navi-link"
                      href="${data.certificate_path}"
                      target="_blank"
                    >
                      <span class="navi-icon">
                        <i class="la la-download icon-xl"></i>
                      </span>
                      Download Sertifikat
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          `;
        },
      }
    ]
  }
}
