import { Controller } from "stimulus"
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import 'videojs-contrib-quality-levels'
import 'videojs-hls-quality-selector'
import 'videojs-thumbnail-sprite'

export default class extends Controller {
  static targets = [ 'content' ];

  initialize() {
    this.playerTarget = this.element.querySelector("video");
    this.bindedPause = this.pause.bind(this);
  }

  connect(){
    this.element.addEventListener("mouseover", this.handleMouseover.bind(this));
    this.element.addEventListener("mouseout", this.handleMouseout.bind(this));
    this.contentTarget.addEventListener('mouseover', this.handleContentMouseover.bind(this));
    this.contentTarget.addEventListener('mouseout', this.handleContentMouseout.bind(this));
  }

  disconnect(){
  }

  handleContentMouseover(){
    this.contentTarget.classList.remove('opacity-0');
  }
  handleContentMouseout(){
    this.contentTarget.classList.add('opacity-0');
  }

  handleMouseover(){
    if (this.videoPlayer){
      if(this.videoPlayer.paused()){
        this.videoPlayer.play();
      }
      return;
    }

    this.videoPlayer = videojs(this.playerTarget, {
      muted: true,
      controls: false,
      fluid: true,
      loadingSpinner: false,
      userActions: {
        doubleClick: false
      }
    })
    this.currentSource = this.videoPlayer.currentSource();
    this.videoPlayer.play();

    this.videoPlayer.on('timeupdate', function (e) {
      if (this.videoPlayer.currentTime() >= 15) {
        this.videoPlayer.currentTime(1);
      }
    }.bind(this));
  }

  get bannerContainer(){
    if(this._bannerContainer){
      return this._bannerContainer;
    }
    this._bannerContainer = window.Util.findParentElement('js-banner-item-container', this.element);
    return this._bannerContainer;
  }

  handleMouseout(e){
    if(e.toElement && (e.toElement.classList.contains('js-banner-content') || e.toElement.tagName === 'VIDEO')){
      return;
    }
    this.videoPlayer.pause();
  }
}
