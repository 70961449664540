import { Controller } from "stimulus"
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import 'videojs-contrib-quality-levels'
import 'videojs-hls-quality-selector'
import 'videojs-thumbnail-sprite'

export default class extends Controller {
  connect() {   
    if (this.element.dataset.onhover == 'true'){
      this.element.addEventListener("mouseover", this.initVideo.bind(this));
      this.element.addEventListener("mouseover", this.hoverVideo.bind(this));
      this.element.addEventListener("mouseout", this.disposeVideo.bind(this));
    }
  }

  initialize() {}

  disconnect() {}

  initVideo(){
    this.playerTarget = this.element.querySelector("video")    
    if (this.videoPlayer){
      this.videoPlayer.src(this.currentSource);
    }else{
      this.videoPlayer = videojs(this.playerTarget, {
        muted: true,
        controls: false,
        fluid: true,
        loadingSpinner: false,
        userActions: {
          doubleClick: false
        }
      })
      this.currentSource = this.videoPlayer.currentSource();
    }

    if (this.element.dataset.repeat == 'true'){
      this.repeatVideo();
    }
  }

  disposeVideo(){
    this.videoPlayer.reset();
  }

  hoverVideo(){
    this.videoPlayer.bigPlayButton.hide();      
    this.videoPlayer.controlBar.hide();
    // this.videoPlayer.muted(true); 

    this.element.addEventListener('mouseenter', function() {
      this.videoPlayer.play();
    }.bind(this));

    this.element.addEventListener('mouseleave', function() {
      this.videoPlayer.pause();
    }.bind(this));

    this.videoPlayer.on('timeupdate', function (e) {
      if (this.videoPlayer.currentTime() >= 30) {
        this.videoPlayer.currentTime(1);
      }
    }.bind(this));

    this.videoPlayer.pause();
  }

  repeatVideo(){
    this.videoPlayer.bigPlayButton.hide();      
    this.videoPlayer.controlBar.hide();
    // this.videoPlayer.muted(true); 
    this.videoPlayer.pause();

    if (this.element.dataset.isplay == "true") {
      this.videoPlayer.play();
    }

    this.videoPlayer.on('timeupdate', function (e) {
      if (this.videoPlayer.currentTime() >= 10) {
        this.videoPlayer.currentTime(1);
      }
    }.bind(this));
  }
}
